

$(function() {
	
	
	var maxW
	var originalW
	var clicked
	var expanded = false
	const sTime = 200

	stopVideos()

	$('#fill').hide()
	$('#aboutcontainer').hide()
	$("#drag").draggable()

	$('#about').on('click', function() {
		stopVideos()

		$('#fill').hide()
		
		//slide left and hide
		$('#bottomcontainer').css({position: "relative"})
		$('#bottomcontainer').animate( {left: "-100%"}, sTime)
		setTimeout(function(){
			$('#bottomcontainer').delay(sTime).hide()
		}, sTime)

		
		//slide left and show
		setTimeout(function(){
			$('#aboutText').html("<span style=\"font-size: 2rem; margin-left: -8px; color: white\">Hey! My name is Koen.</span> <br><br> I'm an Interaction Designer living in Utrecht, NL. Creative technologies excite me greatly and I love to work on concepts with a fun-factor. I believe good design is an iterative process, always considering the user first. <br><br>If you'd like to contact me you can always send me an <a style=\"color: black\" href=\"mailto:koenvdwaal@gmail.com\">email!</a>")
			$('#aboutcontainer').show()
			$('#aboutimg').attr("src","https://i.imgur.com/jA7jTbl.png");
			$('#aboutcontainer').css({position: "relative", left: "100%"})
			$('#aboutcontainer').animate( {left: "0%"}, sTime)
				setTimeout(function(){
					$('#aboutcontainer').css({position: "static"})
				}, sTime)
		}, sTime)
		$(".highlighted").each(function() {
			$(this).removeClass("highlighted")
		})
	})

	$('#work').on('click', function() {
		stopVideos()

		//slide right and hide
		$('#aboutcontainer').css({position: "relative"})
		$('#aboutcontainer').animate( {left: "100%"}, sTime)
		setTimeout(function(){
			$('#aboutcontainer').delay(sTime).hide()
		}, sTime)
		
		//slide right and show
		setTimeout(function(){

			$('#bottomcontainer').animate( {left: "0%"}, sTime)
			$('#bottomcontainer').show()

			setTimeout(function(){
				$('#bottomcontainer').css({position: "static"})
			}, sTime)



		}, sTime)

	})

	$('#nav').on('click', 'button', function() {

		$(this).addClass('active').siblings().removeClass('active')
	})

	$('#topWrap, #upperGradient').on('click', function() {
		// $(this).addClass('active').siblings().removeClass('active')
		// if($(this).attr('id') !==)
		stopVideos()

		if(expanded){
			reset()
		}
	})

	
	$( document ).ready(function() {
		if(window.innerWidth < 1300) {
			$('#bottomcontainer').children("*").css("width: 180px")
			originalW = 180
		}
		else if(window.innerWidth < 945) {
			$('#bottomcontainer').children("*").css("width: 160px")
			originalW = 160
		}
		else if(window.innerWidth < 600) {
			$('#bottomcontainer').children("*").css("width: 150px")
			originalW = 150
		} else {
			$('#bottomcontainer').children("*").css("width: 200px")
			originalW = 200
		}
	})

	$(window).resize(function() {
		if(window.innerWidth < 1300) {
			$('#bottomcontainer').children("*").css("width: 180px")
			originalW = 180
		}
		if(window.innerWidth < 945) {
			$('#bottomcontainer').children("*").css("width: 160px")
			originalW = 160
		}
		if(window.innerWidth < 600) {
			$('#bottomcontainer').children("*").css("width: 150px")
			originalW = 150
		} else {
			$('#bottomcontainer').children("*").css("width: 200px")
			originalW = 200
		}
	})

	if(isTouchScreendevice()){
		$("#dragframe").hide()
		$(".cursor").hide()
		$(".cursor2").hide()
	}
	

	$('#bottomcontainer').children().on('click', function() { // click .project in bottomcontainer
		clicked = $(this).attr('id')
		if($(this).hasClass("highlighted")) {
			reset()
		} else {
			$(".highlighted").each(function() {
				$(this).removeClass("highlighted")
			})
			$(this).addClass("highlighted")
			nameCheck(clicked)
			
			if(expanded) {
				$("#bottomcontainer").css("opacity", "0")
				$("#fill").css("opacity", "0")

				$("#bottomcontainer").animate({opacity: 1}, 400)
				$("#fill").animate({opacity: 1}, 400)

			}
			expanded = true
			maxW = parseInt($(this).parent().css("width"))
			$("#bottomcontainer").css("opacity", "0")
				$("#bottomcontainer").animate({opacity: 1}, 400)
			$('#fill').show()
			expanded = true
			$("#bottomcontainer").delay(200).insertAfter("#fill")
			$("#fill").delay(200).animate({opacity: 1}, 400)

			$("html, body").animate({
				scrollTop: 0,
				// pageYOffset: 0
			}, 400)  
		}   
	})

	// $('#fill').on('click', function() { 			// click fill
	// 	// reset()
	// })

})



function reset() {	
			// animate fill disappear
			$("#fill").animate({opacity: 0}, 400)
			//animate botcontainer disappear
			$("#bottomcontainer").animate({opacity: 0}, 400)
			
			expanded = false


	
			// wait out animation + push botcontainer up & hide fill
			setTimeout(function(){
				$('#fill').insertAfter("#bottomcontainer")
				$("#bottomcontainer").css("opacity", "0")
				$("#bottomcontainer").animate({opacity: 1}, 400)
				$('#fill').hide()
				// remove all highlights on projects
				$(".highlighted").each(function() {
				$(this).removeClass("highlighted")
				})
			}, 400)
	
			// scroll up
			$("html, body").animate({
				scrollTop: 0,
				pageYOffset: 0
			}, 400)  
}

function nameCheck(id) {
	if(id == "DTMD") {
		$('#title').html("Donut Touch My Donut")
		$('#row2').html("")
		$('#row2').hide()
		$('#row1').hide()

	}
	if(id == "B") {
		$('#title').html("Blox")
		$('#row1').show()
		$('#projectDescription').html("Blox is a digital sliding puzzle centered around mixing colors. Levels can be completed when the right cube is pushed on its accompanying tile. The game makes use of the subtractive color system. Many mechanics are centered around this. For instance, colors can be mixed, split into two or transformed into a complementary color.<br><br>Play <a target=\"_blank\" style=\"color: black\" href=\"https://simmer.io/@koenfrontatie/blox\">online</a>, or download via itch.io below.")
		$('#projectVideo').html("<iframe class=\"yt_player_iframe\" src=\"https://www.youtube.com/embed/6Q8iX6EPofQ?enablejsapi=1\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture\" allowfullscreen></iframe>")
		$('#row2').show()
		$('#row2').html("<iframe width=\"552\" height=\"167\" frameborder=\"0\" src=\"https://itch.io/embed/965644\"><a href=\"https://koenfrontatie.itch.io/blox\">Blox by koenfrontatie</a></iframe>")	
		$('#gallery').show()		
		$('#gallery').html("<a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/cLb0J79.jpg\"><img src=\"https://i.imgur.com/8GKlDuT.jpg\" /></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/tn7iuYL.jpg\"><img src=\"https://i.imgur.com/KZux6Uw.jpg\"/></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/LXDTlAc.jpg\"><img src=\"https://i.imgur.com/ZmStSBL.jpg\"/></a>")
		

	}
	if(id == "DBT") {
		$('#title').html("Day by Train")
		$('#projectVideo').html("<iframe class=\"yt_player_iframe\" src=\"https://www.youtube.com/embed/0PSInoVNATQ?enablejsapi=1\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture\" allowfullscreen></iframe>")
		$('#row1').show()
		$('#projectDescription').html("Day by Train is a story-driven video game touching on a serious topic. Lead character Elizabeth re-experiences memories from her life during her journey to the beyond. Through gameplay mechanics and storytelling techniques, the player encounters various predicaments of Elizabeth's past life, shining a new light on these moments.<br><br><span class=\"cred\">Made in collaboration with:<br>Yannick Mul / Veerle Brugman / Michael Yamada / Cheyenne Rizzo / Lucas de Jager / Sara Li Wijnia</span>")
		$('#row2').hide()
		$('#row2').html("")
		$('#gallery').show()		
		$('#gallery').html("<a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/M0jzKes.jpg\"><img src=\"https://i.imgur.com/LydvgLs.jpg\" /></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/BTVDdXF.jpg\"><img src=\"https://i.imgur.com/AGONvJp.jpg\" /></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/GgMFJcm.jpg\"><img src=\"https://i.imgur.com/7HpmCRd.jpg\" /></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/kfN7DAP.jpg\"><img src=\"https://i.imgur.com/SFX7wrj.jpg\" /></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/CXGWk8n.jpg\"><img src=\"https://i.imgur.com/Er12KbA.jpg\" /></a>")
	} 
	if(id == "BTBB") {
		$('#title').html("Be the Big Bang")
		$('#projectVideo').html("<iframe class=\"yt_player_iframe\" src=\"https://www.youtube.com/embed/q5qpPvVAgjA?enablejsapi=1\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture\" allowfullscreen></iframe>")
		$('#row2').show()
		$('#row2').html("<iframe width=\"552\" height=\"167\" frameborder=\"0\" src=\"https://itch.io/embed/1542219\"><a href=\"https://koenfrontatie.itch.io/be-the-big-bang\">Be the Big Bang by koenfrontatie</a></iframe>")
		$('#row1').show()
		$('#projectDescription').html("A procedural art project concerning the Universe. The experience responds to microphone input and creates a unique environment each time. The louder the bang, the longer the Universe stays active. Eventually it will collapse.")
		$('#gallery').show()		
		$('#gallery').html("<a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/noOsNOO.jpg\"><img src=\"https://i.imgur.com/6whY6vM.jpg\" /></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/j2YKh9T.jpg\"><img src=\"https://i.imgur.com/s2fgihZ.jpg\"/></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/OcKE9iC.jpg\"><img src=\"https://i.imgur.com/w1MtIUj.jpg\"/></a>")
		
	}
	if(id == "Z") {
		$('#title').html("Zombombie")
		$('#row1').show()
		$('#projectVideo').html("<iframe class=\"yt_player_iframe\" src=\"https://www.youtube.com/embed/4m9BTxXyxnw?enablejsapi=1\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture\" allowfullscreen></iframe>")		
		$('#projectDescription').html("Zombombie is browser based zombie survival game with a slingshot mechanic. The longer you stay alive the harder it gets. Play <a target=\"_blank\" style=\"color: black\" href=\"https://simmer.io/@koenfrontatie/zombombie\">online</a>, or download via itch.io below.")
		$('#row2').show()
		$('#row2').html("<iframe width=\"552\" height=\"167\" frameborder=\"0\" src=\"https://itch.io/embed/1579021\"><a href=\"https://koenfrontatie.itch.io/zombombie\">Zombombie by koenfrontatie</a></iframe>")	
		$('#gallery').hide()		
		$('#gallery').html("")
	}
	if(id == "TTB") {
		$('#title').html("Talking Trash Bin")
		$('#row1').show()
		$('#projectVideo').html("<iframe class=\"yt_player_iframe\" src=\"https://www.youtube.com/embed/XqREj73MCh4?enablejsapi=1\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture\" allowfullscreen></iframe>")		
		$('#projectDescription').html("The Talkin Trash Bin is a storytelling device that talks to anyone who interacts with it. Thanks to the implementation of <a style=\"color: black\" href=\"https://www.flavour.nl/hero-centered-design/\">'Hero Centered Design'<\/a>, TTB appeals to parkgoers that gravitate towards storytelling. When users deposit their trash into the bin, a part of the story reveils itself. By doing this, the Talking Trash Bin motivates its users to stay involved in keeping parks clean. <br><br> <span class=\"cred\">Made in collaboration with:<br>Yasmine Essanoussi / Ayana Tawara / Bart Slot")
		$('#row2').show()
		$('#row2').html("")
		$('#gallery').show()
		$('#gallery').html("<a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/imTGC0W.png\"><img src=\"https://i.imgur.com/imTGC0W.png\" /></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/ehbhnFO.jpg\"><img src=\"https://i.imgur.com/ehbhnFO.jpg\"/></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/jRS9lav.jpg\"><img src=\"https://i.imgur.com/jRS9lav.jpg\"/></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/ayxBLFx.jpg\"><img src=\"https://i.imgur.com/ayxBLFx.jpg\"/></a>")
		

	}
	if(id == "PM") {
		$('#title').html("Planet Music")
		$('#row1').show()
		$('#projectDescription').html("Online audio 'installation' centered around music and how it affects the mood. The interactions express themselves primarily in the field of audio. During this sonic interaction, players are tempted to explore a virtual globe. Depending on their position in this environment, users can gradually hear the composition change. <br><br> Playable <a target=\"_blank\" style=\"color: black\" href=\"https://simmer.io/@koenfrontatie/bridge-the-gap-showoff\">online</a>. <br><br><span class=\"cred\">Made in collaboration with:<br> Nino Saglia / Mark Smits / Bas Boudewijn</span>")
		$('#projectVideo').html("<iframe class=\"yt_player_iframe\" src=\"https://www.youtube.com/embed/Ghg9vCqTcuc?enablejsapi=1\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture\" allowfullscreen></iframe>")
		$('#row2').hide()
		$('#row2').html("")
		$('#gallery').hide()		
		$('#gallery').html("")

	}
	if(id == "N") {
		$('#title').html("Nukemap")
		$('#row2').hide()
		$('#row2').html("")
		$('#gallery').hide()		
		$('#gallery').html("")

	}
	if(id == "DM") {
		$('#title').html("3D Models")
		$('#row1').hide()
		$('#row2').show()
		$('#row2').html("<p style=\"text-align: center\" class=\"description\">Below you'll find some 3D work I've done.</p>")
		$('#gallery').show()		
		$('#gallery').html("<a data-fancybox=\"gallery\" data-caption=\"The assignment was to transform this concept art into a 3D model\" data-src=\"https://i.imgur.com/GW4yaUm.jpg\"> <img src=\"https://i.imgur.com/GW4yaUm.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/NoQ0e7c.jpg\"> <img src=\"https://i.imgur.com/NoQ0e7c.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/jya2K20.jpg\"> <img src=\"https://i.imgur.com/jya2K20.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/iE8xxrW.jpg\"> <img src=\"https://i.imgur.com/iE8xxrW.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/3ylJ0eS.jpg\"> <img src=\"https://i.imgur.com/3ylJ0eS.jpg\" /> </a> <a data-fancybox=\"gallery\" data-caption=\"The assignment was to conceptualize a throne for this Pumpkin King character.\" data-src=\"https://i.imgur.com/hAwKtKa.jpg\"> <img src=\"https://i.imgur.com/hAwKtKa.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/0EPgx1m.jpg\"> <img src=\"https://i.imgur.com/0EPgx1m.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/PDxDd3g.jpg\"> <img src=\"https://i.imgur.com/PDxDd3g.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/rYXwnrM.jpg\"> <img src=\"https://i.imgur.com/rYXwnrM.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/E5m8HSY.jpg\"> <img src=\"https://i.imgur.com/E5m8HSY.jpg\" /> </a> <a data-fancybox=\"gallery\" data-caption=\"Other work.\" data-src=\"https://i.imgur.com/xyYv3zs.jpg\"> <img src=\"https://i.imgur.com/xyYv3zs.jpg\" /> </a> <a data-fancybox=\"gallery\" data-caption=\"Other work.\" data-src=\"https://i.imgur.com/DXzjsyQ.jpg\"> <img src=\"https://i.imgur.com/DXzjsyQ.jpg\" /> </a>")
	}
	if(id =="SS") {
		$('#title').html("Singing Sirens")
		$('#row2').hide()
		$('#row2').html("")
		$('#gallery').hide()		
		$('#gallery').html("")
	}

	if(id =="SJS") {
		$('#title').html("SjoelSim!")
		$('#row1').show()
		$('#projectDescription').html("SjoelSim! Is a mixed-reality version of the popular and historic Dutch shuffleboard game \"Sjoelen\". The circuit makes use of two ultrasonic distance sensors to measure the X and Z velocity of a playing disc. In combination with a program built in the Unity Engine, it makes for a pretty good alternative to the real deal.")
		$('#projectVideo').html("<iframe class=\"yt_player_iframe\" src=\"https://www.youtube.com/embed/aeGiUjdFPv8?enablejsapi=1\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture\" allowfullscreen></iframe>")
		$('#row2').show()
		$('#row2').html("<a href=\"https://www.instructables.com/SjoelSim/\"><img style=\"border: 1px solid darkgrey\" src=\"https://i.imgur.com/d6oi9W5.jpg\" alt=\"link to instructable\"></a>")
		$('#gallery').show()		
		$('#gallery').html("<a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/2SW9GJP.png\"><img src=\"https://i.imgur.com/2SW9GJP.png\" /></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/zxVhyr3.png\"><img src=\"https://i.imgur.com/zxVhyr3.png\"/></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/HouV28Z.jpg\"><img src=\"https://i.imgur.com/HouV28Z.jpg\"/></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/FRhBdhc.jpg\"><img src=\"https://i.imgur.com/FRhBdhc.jpg\"/></a>")
	}

	if(id =="MM") {
		$('#title').html("Monster Mash")
		$('#row1').show()
		$('#projectDescription').html("Monster Mash is a conceptual introductory activity for new students of the HKU. During this event, participants work together in groups and compete in a large tournament. Over two days, groups work iteratively on a monster design that represents their group in battles. These take place publicly, (on a big display) after which the audience is asked to vote. The tournament is decided based on the voting system and special powerups that can be earned through real-life activities.<br><br><span class=\"cred\">Made in collaboration with:<br>Yasmine Essanoussi / Lucas de Jager / Sharon Künne / David Verhoef / Valentine van Steenbergen</span>")
		$('#projectVideo').html("<iframe class=\"yt_player_iframe\" src=\"https://www.youtube.com/embed/6_Teyi05PDU?enablejsapi=1\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture\" allowfullscreen></iframe>")
		$('#row2').hide()
		$('#gallery').show()		
		$('#gallery').html("<a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/7kr32l5.png\"><img src=\"https://i.imgur.com/7kr32l5.png\" /></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/Jvtvk52.png\"><img src=\"https://i.imgur.com/Jvtvk52.png\"/></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/xTipBOJ.png\"><img src=\"https://i.imgur.com/xTipBOJ.png\"/></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/pseTX1w.png\"><img src=\"https://i.imgur.com/pseTX1w.png\"/></a><a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/axHEAQF.png\"><img src=\"https://i.imgur.com/axHEAQF.png\"/></a>")
	}
	
	if(id =="L") {
		$('#title').html("Lifeline")
		$('#row1').show()
		$('#projectDescription').html("A short animated film about a dystopian vision for the future. <br><br><span class=\"cred\"> Made in collaboration with: <br> Mercedes Aartsen / Anita Suetens / Mark van Disseldorp / Lucas de Jager</span>")
		$('#projectVideo').html("<iframe class=\"yt_player_iframe\" src=\"https://www.youtube.com/embed/lJYUWPctB0A?enablejsapi=1\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture\" allowfullscreen></iframe>")
	$('#row2').show()
		$('#row2').html("")
		$('#gallery').hide()		
		$('#gallery').html("")

	}

	if (id == "PP") {
		$('#title').html("Poku Planets")
		$('#row1').show()
		$('#projectDescription').html("Poku Planets is a music app that introduces children to music production in a fun and engaging way. Using aliens, planets, and solar systems to visualize the process, children can create compositions with the help of artificial intelligence. The app aims to make music creation accessible to a broad audience and showcase how AI can enhance the creative process.")

		//$('#projectDescription').html("Poku Planets is a music app that introduces children to music production in a fun and engaging way. Using aliens, planets, and solar systems to visualize the process, children can create compositions with the help of artificial intelligence. The app aims to make music creation accessible to a broad audience and showcase how AI can enhance the creative process. In the app, aliens represent different sounds, and placing them on the rings of planets integrates them into music loops. These planets are then arranged within a solar system, allowing for unique combinations. The AI features enable the creation of variations on music loops, with the helper character, Ron Robotron, taking over planets to give them unique sounds at the player's request.<br><br><span class=\"cred\"> Made in collaboration with: <br> Quinto Veldhuyzen / Nout Heesink</span>")
		$('#projectVideo').html("<iframe class=\"yt_player_iframe\" src=\"https://www.youtube.com/embed/EhuWTgt7rxE?si=ZvIdPtoVPtW_enQj?enablejsapi=1\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture\" allowfullscreen></iframe>")
		$('#row2').show()
		$('#row2').html("<img id=\"wrapperWrapper\" width =\"552\" src=\"https://i.imgur.com/aXXZQsh.png\"> <p  width =\"552\" class=\"description\"> In this app, aliens represent different sounds. Players place these aliens on planetary rings to create music loops. They then arrange these planets in a solar system to form compositions. The app's AI helper, Ron Robotron, can inhabit a planet at the player's request and create variations on player-made sequences using various prompts. Ron's ability to modify and enhance the planet-based tunes allows players to add more variety to their music. <br><br><span class=\"cred\"> Made in collaboration with: <br> Quinto Veldhuyzen / Nout Heesink</span></p>")

		$('#gallery').show()		
		$('#gallery').html("<a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/PH92oD5.jpeg\"> <img src=\"https://i.imgur.com/PH92oD5.jpeg\" /> </a><a data-fancybox=\"gallery\" data-caption=\"Studio Moooon\" data-src=\"https://i.imgur.com/l5I3Pmi.jpeg\"> <img src=\"https://i.imgur.com/l5I3Pmi.jpeg\" /> </a> <a data-fancybox=\"gallery\" data-caption=\"During a user testing day\" data-src=\"https://i.imgur.com/Pp2jMVj.jpeg\"> <img src=\"https://i.imgur.com/Pp2jMVj.jpeg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/yMDZD1T.png\"> <img src=\"https://i.imgur.com/yMDZD1T.png\" /> </a> <a data-fancybox=\"gallery\" data-caption=\"Shaped by direct input from our key audience.\" data-src=\"https://i.imgur.com/eZirhHN.jpeg\"> <img src=\"https://i.imgur.com/eZirhHN.jpeg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/EuVQnvk.jpeg\"> <img src=\"https://i.imgur.com/EuVQnvk.jpeg\" /> </a>")
		
		//https://i.imgur.com/eZirhHN.jpeg $('#gallery').html("<a data-fancybox=\"gallery\" data-caption=\"The assignment was to transform this concept art into a 3D model\" data-src=\"https://i.imgur.com/GW4yaUm.jpg\"> <img src=\"https://i.imgur.com/GW4yaUm.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/NoQ0e7c.jpg\"> <img src=\"https://i.imgur.com/NoQ0e7c.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/jya2K20.jpg\"> <img src=\"https://i.imgur.com/jya2K20.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/iE8xxrW.jpg\"> <img src=\"https://i.imgur.com/iE8xxrW.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/3ylJ0eS.jpg\"> <img src=\"https://i.imgur.com/3ylJ0eS.jpg\" /> </a> <a data-fancybox=\"gallery\" data-caption=\"The assignment was to conceptualize a throne for this Pumpkin King character.\" data-src=\"https://i.imgur.com/hAwKtKa.jpg\"> <img src=\"https://i.imgur.com/hAwKtKa.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/0EPgx1m.jpg\"> <img src=\"https://i.imgur.com/0EPgx1m.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/PDxDd3g.jpg\"> <img src=\"https://i.imgur.com/PDxDd3g.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/rYXwnrM.jpg\"> <img src=\"https://i.imgur.com/rYXwnrM.jpg\" /> </a> <a data-fancybox=\"gallery\" data-src=\"https://i.imgur.com/E5m8HSY.jpg\"> <img src=\"https://i.imgur.com/E5m8HSY.jpg\" /> </a> <a data-fancybox=\"gallery\" data-caption=\"Other work.\" data-src=\"https://i.imgur.com/xyYv3zs.jpg\"> <img src=\"https://i.imgur.com/xyYv3zs.jpg\" /> </a> <a data-fancybox=\"gallery\" data-caption=\"Other work.\" data-src=\"https://i.imgur.com/DXzjsyQ.jpg\"> <img src=\"https://i.imgur.com/DXzjsyQ.jpg\" /> </a>")
	}
}

function isTouchScreendevice() {
	return (navigator.maxTouchPoints > 1);     
}

function stopVideos() {
	$('.yt_player_iframe').each(function(){
		this.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
		// $("this").attr("src","\"\" ");
	  });
	
}





// if(parseInt($(this).css("width")) < parseInt(maxWidth) - 100) {
// 	// $(this).parent().css("flex-direction", "column")
// 	 $(this).css("order", "-1")

	
// 	$(this).delay(200).animate({width: maxWidth}, 200)
// 	$(this).delay(200).animate({height: '800px'}, 400)
// 	$('.project').each(function(i, obj) {
// 		if($(this).attr('id') != clicked) {
// 			 $(this).animate({opacity: '0'}, 200)
// 		}
// 	})

// } else {
// 	$(this).animate({height: '200px'}, 400)
// 	$(this).animate({width: '200px'}, 200)
// 	$(this).delay(400).css("order", "0")
// 	$(this).css("position", "initial")


// 	$('.project').each(function(i, obj) {
// 		if($(this).attr('id') != clicked) {
// 			 $(this).animate({opacity: '100'}, 200)
// 		}
// 	})
// }





		