function is_touch_enabled() {
	return (navigator.maxTouchPoints > 1);
}

if(!is_touch_enabled()) {

var cursor = document.querySelector('.cursor')
var cursorinner = document.querySelector('.cursor2')
var a = document.querySelectorAll('a')

document.addEventListener('mousemove', function(e){
  var x = e.clientX
  var y = e.clientY
  cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
  // cursor.style.transform = 'rotate3d(50deg)'
  
})

document.addEventListener('mousemove', function(e){
  var x = e.clientX
  var y = e.clientY
  cursorinner.style.left = x + 'px'
  cursorinner.style.top = y + 'px'
})
}