import './style.css'
import './jqueryScript.js'
import './cursor.js'



import * as THREE from 'three'
import { Fancybox } from "@fancyapps/ui"
import "@fancyapps/ui/dist/fancybox.css"
import anime from 'animejs/lib/anime.es.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'  //examples/jsm/controls/TrackballControls.js
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { Vector2 } from 'three'
import { stagger } from 'animejs'

//animejs
var targets = document.querySelectorAll('.cube');
var headtarget = document.querySelectorAll('.webgl');


var wavecolor = '#D28CCD';

var cubesInit = 0;
var seekInit = 0;
var intervalWatcher = 0;

var cursorX = 0;
var cursorY = 0;

var hoveredElement = 1000;
var waveAnimDone = false;
var mouseInRange = false;

function everyInterval(interval, deltaTime) {
    if(cubesInit < targets.length) {
        intervalWatcher += deltaTime
        if(intervalWatcher > interval) {
            // console.log("now!");
            startCube(targets[cubesInit])
            cubesInit++;
            intervalWatcher = 0;
        }
    } else {
        if(!waveAnimDone)waveAnimDone = true
    }
}

function startCube(target) {
    anime({
        targets: target,
        keyframes: [
            {translateY: 100, backgroundColor: wavecolor},
        ],
        direction: 'alternate',
        duration: 1000,
        loop: true,
        easing: 'easeInOutSine'
        })
}

var topcontainer = document.getElementById('topcontainer')

function setHoveredCube() {
    checkIfMouseInRange();
    for(var i = 0; i < targets.length; i++) {
        if(cursorX > targets[i].getBoundingClientRect().left && cursorX < targets[i].getBoundingClientRect().right && mouseInRange && waveAnimDone) {        
            if(hoveredElement != i) {
                    // console.log('cursor is at ' + i)
                    //word groot

                    enlargeElement(targets[i-1], 1.5)
                    enlargeElement(targets[i+1], 1.5)
                    enlargeElement(targets[i], 2.5)
                    // hoveredElement = i
                    
                }
            }
    }
}

function enlargeElement(element, scale) {
    // var c = Math.floor(Math.random() * 2)
    // if(c == 1)var rstring = '1turn'
    // if(c == 0)var rstring = '-1turn'
    anime({
        targets: element,
        scale: scale,
        easing: 'easeOutSine',
        duration: 250,
        rotate: '-1turn',
        backgroundColor: '#000000',
    })
}


function checkIfMouseInRange() {
    if(!mouseInRange) {
        if(cursorY < topcontainer.getBoundingClientRect().bottom) {
            // console.log('mouse in range');

            mouseInRange = true;
        }
    } else {
        if(cursorY > topcontainer.getBoundingClientRect().bottom) {
            // console.log('mouse out range');

            mouseInRange = false;
        }
    }
}

anime({
    targets: headtarget,
    keyframes: [
        {translateY: -5},
    ],
    direction: 'alternate',
    duration: 1000,
    loop: true,
    easing: 'easeInOutSine'
})

//check mobile
function is_touch_enabled() {
	return (navigator.maxTouchPoints > 1);
}


if(!is_touch_enabled()) {
    $('#cubecontainer').delay( 1000 ).animate({opacity: 1}, 3000)
}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
// scene.background = new THREE.Color('tan')

// const cube1 = new THREE.Mesh(
//     new THREE.BoxGeometry(.3,.3,.3),
//     new THREE.MeshBasicMaterial({color: 'white'})
// )
// scene.add(cube1)
//model loader
const model = new THREE.Group()
const gltfLoader = new GLTFLoader()
gltfLoader.load(
    '/models/Head/Head_Stylized.glb',
    (glb) => {
        console.log('success')
        
        glb.scene.position.set(-.07, -1, 0)
        
        
        model.add(glb.scene)
        


    },
    () => {
        console.log('progress')
    },
    () => {
        console.log('error')
    }
)
scene.add(model)

const plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), -1)
// const x = new THREE.AxesHelper(9,9,9)
// scene.add(x)

// const planehelper = new THREE.PlaneHelper(plane) 
// scene.add(planehelper)

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 1)
scene.add(ambientLight)
const directionalLight = new THREE.DirectionalLight(0xFFEFD8, 1)
directionalLight.castShadow = true

directionalLight.position.set(3, -3, 3)
scene.add(directionalLight)

const raycaster = new THREE.Raycaster()

/**
 * Sizes
 */
const heightF = 0.3

const sizes = {
    width: window.innerHeight * heightF,
    height: window.innerHeight * heightF
}

if(innerHeight * heightF > 200) {
    sizes.width = innerHeight * heightF
    sizes.height = innerHeight * heightF
   } else {
       sizes.width = 200
       sizes.height = 200
}

window.addEventListener('resize', () => {
    // Update sizes
    if(innerHeight * heightF > 200) {
     sizes.width = innerHeight * heightF
     sizes.height = innerHeight * heightF
    } else {
        sizes.width = 200
        sizes.height = 200
    }

    //Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

const cursor = new Vector2()

//let target = new THREE.Vector3(cursor.x, cursor.y, 4)

window.addEventListener('mousemove', (event) => {
    // cursor.x = ((event.clientX - window.innerHeight * heightF/2)/ window.innerWidth) 
    cursor.x = (event.clientX / window.innerWidth) - 0.5 
    // console.log(cursor.x)
    cursor.y = - (event.clientY / window.innerHeight + heightF)
    //console.log(cursor)
    cursorX = event.clientX
    cursorY = event.clientY
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0, 0, 4.5)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0.75, -0.3)
controls.enableDamping = true
controls.enablePan = false
controls.enableZoom = false
controls.noPan = true
controls.noZoom = true
controls.dynamicDampingFactor = 0.1


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.shadowMap.enabled = true
renderer.antialias = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0
const targetQuaternion = new THREE.Quaternion();

const tick = () => {
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
    
	let target = new THREE.Vector3()
    raycaster.setFromCamera(cursor, camera)
    raycaster.ray.intersectPlane(plane, target)

    // cube1.position.set(target.x, target.y ,0 )

    // cube1.position.set(target.position)

    // Update controls
    controls.update()


    if(!is_touch_enabled()) {
		
		targetQuaternion.setFromEuler(model.rotation);

        //model.lookAt(target)
		
		// Calculate the direction from the model to the target
		const direction = new THREE.Vector3().subVectors(target, model.position).normalize();

		// Set the target quaternion based on the direction
		targetQuaternion.setFromRotationMatrix(
			new THREE.Matrix4().lookAt(direction, new THREE.Vector3(0, 0, 0), model.up)
		);
		
		const lerpFactor = 0.2; // Adjust this value to control smoothing (0-1)
		model.quaternion.slerp(targetQuaternion, lerpFactor);

        controls.enabled = false
        
		everyInterval(.150, deltaTime)
        // setHoveredCube()
    } else {
        controls.autoRotate = true

        
    }
    // cube1.position.x += 0.01
    
    

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()
